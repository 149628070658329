import React from "react";
import { elementType } from "prop-types";
import ApplicationError from "@insightfulscience/smart-react/ApplicationError";
import isInt from "@insightfulscience/shared-utils/parsers/isInt";
import errorPropType from "./error-prop-type";
import Error400 from "./Error400";
import Error401 from "./Error401";
import Error403 from "./Error403";
import Error404 from "./Error404";
import Error500 from "./Error500";
import Error503 from "./Error503";
import ErrorDetails from "./ErrorDetails";
import ErrorLayout from "./ErrorLayout";
import { notifySentry } from "../sentry";

const errorComponents = {
	400: Error400,
	401: Error401,
	403: Error403,
	404: Error404,
	503: Error503,
};

const AppError = ({ error, Layout = ErrorLayout, ...props }) => {
	const { code } = error;
	const ErrorComponent = errorComponents[code] || Error500;

	return (
		<Layout extra={<ErrorDetails error={error} />} {...props}>
			<ErrorComponent error={error} />
		</Layout>
	);
};

AppError.getInitialProps = context => {
	const { err, res } = context;

	const error = ApplicationError.of(err ?? {});
	const errorCode = isInt(error.code) ? error.code : 500;

	if (res) {
		res.statusCode = errorCode;
	}

	notifySentry(err, context);

	if (error.code >= 500 && error.code !== 503 && error.originalError != null)
		throw error.originalError;

	return { error: error.toSerializable() };
};

AppError.propTypes = {
	error: errorPropType,
	Layout: elementType,
};

export default AppError;
